/* eslint-disable import/prefer-default-export */
import AvenirBook from './Avenir-Book.otf';
import AvenirBlack from './Avenir-Black.otf';
import AvenirHeavy from './Avenir-Heavy.ttf';
import AvenirMedium from './Avenir-Medium.ttf';

export const Avenir = [
  {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      url(${AvenirBook}) format('opentype')
    `,
  },
  {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
      url(${AvenirMedium}) format('truetype')
    `,
  },
  {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 800,
    src: `
      url(${AvenirBlack}) format('opentype')
    `,
  },
  {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 900,
    src: `
      url(${AvenirHeavy}) format('truetype')
    `,
  },
];
