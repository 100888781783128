import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    minWidth: 220,
    zIndex: 9999,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
      width: '100%',
    },
  },
}));

export default function DownloadButton() {
  const classes = useStyles();

  const handleClick = (event) => {
    event.preventDefault()
    // TODO: do something with form values
    navigate("http://hyperurl.co/aj8is2")
  }
  return (
    <Button onClick={handleClick} variant="contained" color="primary" size="large" className={classes.root}>
      Download the App
    </Button>
  );
}
