import React from 'react';
import { Container, Grid, makeStyles, Typography, Link, Box, Hidden } from '@material-ui/core';

import Logo from '../../assets/images/logo.inline.svg';

import FacebookIcon from '../../assets/icons/facebook.inline.svg';
import InstagramIcon from '../../assets/icons/instagram.inline.svg';
import TwitterIcon from '../../assets/icons/twitter.inline.svg';
import LinkedinIcon from '../../assets/icons/linkedin.inline.svg';
import { Link as GLink } from 'gatsby';
import { navigate } from 'gatsby';

import DownloadButton from '../../components/downloadButton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(9, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 0),
    },
  },

  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  buttonWrapper: {
    textAlign: 'right',
  },

  container: {
    padding: theme.spacing(9, 0, 3),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 0),
      flexDirection: 'column',
    },
  },

  socialsContainer: {
    flexGrow: 1,
    textAlign: 'right',
    '& > *': {
      paddingRight: 10,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      paddingTop: 50,
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  const navigateTo = url => {
    navigate(url);
  };

  return (
    <section className={classes.root}>
      <Container>
        <Grid container spacing={8}>
          <Grid item xs={12} md="auto">
            <Logo />
          </Grid>
          <Grid item xs={12} md="auto">
            <Box maxWidth={196}>
              <Typography>Organize your FamTrip knowledge so you can sell more travel</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md="auto">
            <div className={classes.linksContainer}>
              <Link
                href="#"
                color="textPrimary"
                onClick={e => {
                  navigateTo('/privacy-policy');
                }}
              >
                Privacy Policy
              </Link>
              <Link
                href="#"
                color="textPrimary"
                onClick={e => {
                  navigateTo('/terms');
                }}
              >
                Terms of Use
              </Link>
              <Link
                href="#"
                color="textPrimary"
                onClick={e => {
                  navigateTo('/eula');
                }}
              >
                EULA
              </Link>
            </div>
          </Grid>
         
            <Grid item md>
              <div className={classes.buttonWrapper}>
                <DownloadButton />
              </div>
            </Grid>
        
        </Grid>
        <div className={classes.container}>
          <Typography>© Copyright FamGuru 2021</Typography>
          <div className={classes.socialsContainer}>
            <Link href="https://www.facebook.com/FamGuruHQ/" rel="noopener noreferrer" target="_blank">
              <FacebookIcon />
            </Link>
            <Link href="https://www.instagram.com/FamGuruHQ/" rel="noopener noreferrer" target="_blank">
              <InstagramIcon />
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
}
