import React from 'react';
import { makeStyles, Typography, Container, Box, useMediaQuery, Grid, Link } from '@material-ui/core';

import googleIcon from '../../assets/images/google.svg';
import appelIcon from '../../assets/images/appel.svg';

import PartnerA from '../../assets/images/partner-gyg.inline.svg';
import PartnerB from '../../assets/images/partner-klook.inline.svg';
import PartnerC from '../../assets/images/partner-redballoon.inline.svg';
import PartnerD from '../../assets/images/partner-viator.inline.svg';
import PartnerE from '../../assets/images/partner-tripadvisor.inline.svg';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(9, 0),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 0),
    },
  },

  icon: {
    [theme.breakpoints.down('sm')]: {
      height: 42,
    },
  },

  spacer: {
    padding: theme.spacing(8, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
}));

export default function Partners() {
  const classes = useStyles();

  const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <section className={classes.root}>
      <Container>
        <Typography variant="subtitle1" align="center">
          FOR AGENTS BY AGENTS
        </Typography>
        <Box paddingY={2} />
        <Typography variant="h1" align="center" paragraph>
          Join over 30,000+ happy users & and learn fast
        </Typography>
        <Typography variant="h2" align="center" paragraph={matches}>
          You love traveling, want more and better clients, and have no time. We get that.
        </Typography>
        <Typography variant="h2" align="center">
          FamGuru helps make Travel Advising easier and more lucrative.
        </Typography>
        <Box paddingTop={6}>
          <Grid container spacing={6} justify="center">
            <Grid item xs="auto" md="auto">
              <Link href="/">
                <img alt="icon" src={appelIcon} className={classes.icon} />
              </Link>
            </Grid>
            <Grid item xs="auto" md="auto">
              <Link href="/">
                <img alt="icon" src={googleIcon} className={classes.icon} />
              </Link>
            </Grid>
          </Grid>
        </Box>
        <div className={classes.spacer} />
        <Typography variant="subtitle1" align="center" className={classes.subtitle}>
          WE ARE PROUD TO HAVE SOME BIG BRANDS AS OUR PARTNERS
        </Typography>
        <Box paddingY={2} />
        <Typography variant="h1" align="center" paragraph>
          Join over 30,000+ happy users & and learn fast
        </Typography>
        <Box paddingTop={4}>
          <Grid container spacing={8} justify="space-between" alignItems="center">
            <Grid item xs={12} md="auto">
              <PartnerA />
            </Grid>
            <Grid item xs={12} md="auto">
              <PartnerB />
            </Grid>
            <Grid item xs={12} md="auto">
              <PartnerC />
            </Grid>
            <Grid item xs={12} md="auto">
              <PartnerD />
            </Grid>
            <Grid item xs={12} md="auto">
              <PartnerE />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
