import React from 'react';
import { Link } from 'gatsby';
import { makeStyles, AppBar, Toolbar, Container, Link as MuiLink, Hidden, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Logo from '../../assets/images/logo.svg';
import Footer from '../footer';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
    backgroundColor: '#fff',
    zIndex: 10000,
    top: 0,
    overflow: 'auto',
  },
  toolbar: {
    padding: 0,
  },
  appBar: {
    padding: theme.spacing(5, 0),
  },
  logoWrapper: {
    flexGrow: 1,
    paddingRight: '12px',
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  buttonWrapper: {
    padding: theme.spacing(9, 0, 5),
    '& > *': {
      maxWidth: 219,
    },
  },
}));

export default function MobileMenu({ onClose }) {
  const classes = useStyles();

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Hidden mdUp>
      <div className={classes.root}>
        <Container>
          <AppBar className={classes.appBar} position="static" color="transparent">
            <Toolbar className={classes.toolbar}>
              <div className={classes.logoWrapper}>
                <MuiLink component={Link} to="/">
                  <img alt="logo" src={Logo} />
                </MuiLink>
              </div>
              <IconButton onClick={onClose}>
                <Box color="#000" display="flex">
                  <CloseIcon fontSize="large" />
                </Box>
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.linksContainer}>
            <MuiLink component={Link} onClick={onClose} to="/pricing" color="textPrimary" paragraph>
              PRICING
            </MuiLink>
            <MuiLink component={Link} onClick={onClose} to="/contact" color="textPrimary">
              CONTACT
            </MuiLink>
          </div>
        </Container>
        <Footer />
      </div>
    </Hidden>
  );
}
