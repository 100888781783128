/* eslint-disable react/prop-types */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';

import Header from '../features/header';
import Partners from '../features/partners';
import Footer from '../features/footer';

import theme from '../themes';

const Layout = ({ children, pageContext }) => (
  <ThemeProvider theme={theme}>
    <div>
      <CssBaseline />
      {pageContext.layout === 'alt' ? null : <Header />}
      {children}
     {/*  <Partners /> */}
      <Footer />
    </div>
  </ThemeProvider>
);

export default Layout;
