import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import { Avenir } from '../assets/fonts';

import 'pure-react-carousel/dist/react-carousel.es.css';
import '@fontsource/abril-fatface';

const theme = createMuiTheme({
  shadows: Array(25).fill('none'),
  palette: {
    background: {
      default: '#fff',
    },
    text: {
      secondary: '#fff',
      primary: '#000',
    },
    primary: {
      main: '#DC458B',
    },
    secondary: {
      main: '#E88154',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Avenir',
    fontSize: 16,
    button: {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
    },
    h1: {
      fontSize: 30,
      lineHeight: 1.4,
      fontFamily: 'Abril Fatface',
    },
    h2: {
      fontSize: 20,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: 20,
      fontFamily: 'Abril Fatface',
    },
    subtitle1: {
      color: '#848B9B',
      fontSize: 20,
      lineHeight: 1.2,
      fontWeight: 900,
    },
    h6: {
      fontWeight: 800,
      fontSize: 16,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [...Avenir, 'Abril Fatface'],
      },
    },
    MuiButton: {
      root: {
        borderRadius: 50,
        height: 50,
      },
    },
    MuiStepLabel: {
      label: {
        color: '#000',
        maxWidth: 180,
        margin: 'auto',
      },
    },
    MuiStep: {
      alternativeLabel: {
        minHeight: 132,
      },
    },
    MuiStepper: {
      alternativeLabel: {
        alignItems: 'center',
      },
    },
    MuiContainer: {
      root: {
        overflow: 'hidden',
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderRadius: 8,
        backgroundColor: '#fff',
        '&$focused': {
          backgroundColor: '#fff',
        },
        '&:hover': {
          backgroundColor: '#fff',
          '@media (hover: none)': {
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiInputLabel: {
      filled: {
        color: 'inherit',
        '&$focused': {
          color: 'inherit',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: 123,
        padding: '0 24px',
        '&$expanded': {
          minHeight: 123,
        },
      },
      content: {
        fontSize: 20,
        lineHeight: 1.2,
        '&$expanded': {
          fontWeight: 800,
        },
      },
      expandIcon: {
        '&$expanded': {
          '& #hide': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordion: {
      root: {
        borderBottom: '2px solid #E2E2E2',
        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '0 24px 48px 24px',
      },
    },
  },
});

export default theme;
