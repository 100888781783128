import React from 'react';
import clsx from 'clsx';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { makeStyles, AppBar, Toolbar, Container, Link as MuiLink, Hidden, IconButton, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import LogoAlt from '../../assets/images/logo-alt.svg';
import Logo from '../../assets/images/logo.svg';
import MobileMenu from './mobileMenu';

const useStyles = makeStyles(theme => ({
  root: alt => ({
    padding: theme.spacing(5, 0),
    color: alt && theme.palette.text.secondary,
  }),

  toolbar: {
    padding: 0,
  },

  linksContainer: {
    flexGrow: 1,
    '& > *': {
      marginLeft: theme.spacing(9),
    },
  },

  logoWrapper: {
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      paddingRight: '12px',
    },
  },

  active: {
    fontWeight: 800,
  },
}));

export default function Header({ alt }) {
  const classes = useStyles({ alt });
  const { pathname } = useLocation();
  const [isOpen, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar position="static" color="transparent" className={classes.root}>
        <Container>
          <Toolbar className={classes.toolbar}>
            <div className={classes.logoWrapper}>
              <MuiLink component={Link} to="/">
                {alt ? <img alt="logo" src={LogoAlt} /> : <img alt="logo" src={Logo} />}
              </MuiLink>
            </div>
            <Hidden smDown>
              <div className={classes.linksContainer}>
                <MuiLink
                  component={Link}
                  to="/pricing"
                  color={alt ? 'textSecondary' : 'textPrimary'}
                  className={clsx(pathname.match('pricing') && classes.active)}
                >
                  Pricing
                </MuiLink>
                <MuiLink
                  component={Link}
                  to="/contact"
                  color={alt ? 'textSecondary' : 'textPrimary'}
                  className={clsx(pathname.match('contact') && classes.active)}
                >
                  Contact
                </MuiLink>
              </div>
            </Hidden>
            <Hidden mdUp>
              <IconButton onClick={handleOpen}>
                <Box color={alt ? '#fff' : '#000'} display="flex">
                  <MenuIcon color="inherit" fontSize="large" />
                </Box>
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      {isOpen && <MobileMenu onClose={handleClose} />}
    </>
  );
}
